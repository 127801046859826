import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './components/template/Layout';
import './i18n';
import { UserTypeEnum } from './models/User';
import { ROUTES } from './utils/routes';
import LoadingView from './pages/courses/LoadingView';
import LoginPage from '@/pages/login/LoginPage';
import ResetPassword from '@/pages/login/ResetPassword';
import LoginHandler from '@/pages/login/LoginHandler';
import ProjectGeneralPage from './pages/project/ProjectGeneralPage';
import ProjectSectionPage from './pages/project/ProjectSectionPage';
import ProjectMePage from './pages/project/ProjectMePage';
import ProjectHackathonPage from './pages/project/hackathon/ProjectHackathonPage';
import ProjectHackathonRankingPage from './pages/project/hackathon/ProjectHackathonRankingPage';
import ProjectFormPage from './pages/project/ProjectFormPage';

const Home = lazy(() => import('./pages/dashboard/DashboardPage'));
const TermsOfUsePage = lazy(() => import('./pages/TermsOfUsePage'));
const SkillPage = lazy(() => import('./pages/skill/SkillPage'));

const ConfirmInfoView = lazy(
  () => import('./components/acquisition/ConfirmInfoView'),
);
const TrialLessonSuccessView = lazy(
  () => import('./components/acquisition/TrialLessonSuccessView'),
);
const EditMaterialPage = lazy(
  () =>
    import(
      './components/admin/lessons/material/EditMaterialPage/EditMaterialPage'
    ),
);
const AuthGuard = lazy(() => import('./components/auth/AuthGuard'));
const HowItWorksView = lazy(
  () => import('./components/parents/HowItWorksView'),
);
const ProfilePage = lazy(() => import('./pages/ProfilePage'));
const AccountPage = lazy(() => import('./pages/account/AccountPage'));
const AdminPage = lazy(() => import('./pages/admin/AdminPage'));
const KlassProgressView = lazy(() => import('./pages/admin/KlassProgressView'));
const ManageActivityEdit = lazy(
  () => import('./pages/admin/ManageActivityEdit'),
);
const ManageCourse = lazy(() => import('./pages/admin/ManageCourse'));
const ManageEditingCourse = lazy(
  () => import('./pages/admin/ManageCourseEditing'),
);
const ManageKlass = lazy(() => import('./pages/admin/ManageKlasses'));
const ManageRecessCalendars = lazy(
  () => import('./pages/admin/ManageRecessCalendars'),
);
const ManageStudent = lazy(() => import('./pages/admin/ManageStudent'));
const ManageTeacher = lazy(() => import('./pages/admin/ManageTeacher'));
const ManageTeam = lazy(() => import('./pages/admin/ManageTeam'));
const ManageTrialLesson = lazy(() => import('./pages/admin/ManageTrialLesson'));
const ManageUnit = lazy(() => import('./pages/admin/ManageUnit'));
const SuggestionsPage = lazy(() => import('./pages/admin/SuggestionsPage'));
const CalendarPage = lazy(() => import('./pages/calendar/CalendarPage'));
const CoursesPage = lazy(() => import('./pages/courses/CoursesPage'));
const TeacherFeedbackPage = lazy(
  () => import('./pages/feedback/TeacherFeedbackPage'),
);
const KlassPageAdmin = lazy(() => import('./pages/klass/KlassPageAdmin'));
const KlassView = lazy(() => import('./pages/klass/KlassView'));
const KlassLessons = lazy(() => import('./pages/klass/lessons/KlassLessons'));
const ManageExtraLessonsActivity = lazy(
  () => import('./pages/klass/lessons/ManageExtraLessonsActivity'),
);

const ErrorPage = lazy(() => import('./pages/notFound/ErrorPage'));
const ProjectPage = lazy(() => import('./pages/project/ProjectPage'));
const ProjectView = lazy(() => import('./pages/project/ProjectView'));
const ManageScheduledLessonReport = lazy(
  () => import('./pages/teacher/ManageScheduledLessonReport'),
);
const TeacherPage = lazy(() => import('./pages/teacher/TeacherPage'));
const StudentHistoryPage = lazy(
  () => import('./pages/admin/StudentHistoryPage'),
);
const TopicsPage = lazy(() => import('./pages/admin/TopicsPage'));
const NotificationsPage = lazy(() => import('./pages/admin/NotificationsPage'));

const Router = () => {
  return (
    <Suspense
      fallback={
        <Layout privated={false}>
          <div className="flex items-center justify-center w-full h-full">
            <LoadingView />
          </div>
        </Layout>
      }
    >
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/terms-of-use" element={<TermsOfUsePage />} />
        <Route path="/skills" element={<SkillPage />} />
        <Route path="/skills/:areaId" element={<SkillPage />} />
        <Route path="/projects" element={<ProjectPage />}>
          <Route index element={<ProjectGeneralPage />} />
          <Route path="section/" element={<ProjectSectionPage />} />
          <Route path="me/" element={<ProjectMePage />} />
          <Route
            path={ROUTES.HACKATHON.BASE}
            element={<ProjectHackathonPage />}
          />
          <Route
            path={ROUTES.HACKATHON.RANKING.BASE}
            element={<ProjectHackathonRankingPage />}
          />
        </Route>
        <Route path={ROUTES.PROJECTS.CREATE} element={<ProjectFormPage />} />
        <Route path={ROUTES.PROJECTS.EDIT.BASE} element={<ProjectFormPage />} />

        <Route path="/projects/:id" element={<ProjectView />} />
        <Route
          path="/teacher"
          element={
            <AuthGuard roles={[UserTypeEnum.TEACHER]}>
              <TeacherPage />
            </AuthGuard>
          }
        />
        <Route
          path="/admin"
          element={
            <AuthGuard
              roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
            >
              <AdminPage />
            </AuthGuard>
          }
        />
        <Route
          path="/admin/courses/:isAdd"
          element={
            <AuthGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
              <ManageCourse />
            </AuthGuard>
          }
        />
        <Route
          path={ROUTES.ADMIN.CLASSES.BASE}
          element={
            <AuthGuard
              roles={[
                UserTypeEnum.SUPER_ADMIN,
                UserTypeEnum.UNIT_ADMIN,
                UserTypeEnum.TEACHER,
              ]}
            >
              <ManageKlass />
            </AuthGuard>
          }
        />

        <Route
          path={ROUTES.ADMIN.CLASSES.ACTION(':actionId')}
          element={
            <AuthGuard
              roles={[
                UserTypeEnum.SUPER_ADMIN,
                UserTypeEnum.UNIT_ADMIN,
                UserTypeEnum.TEACHER,
              ]}
            >
              <ManageKlass />
            </AuthGuard>
          }
        />
        <Route
          path={ROUTES.CLASS.PROGRESS(':klassId')}
          element={
            <AuthGuard
              roles={[
                UserTypeEnum.SUPER_ADMIN,
                UserTypeEnum.UNIT_ADMIN,
                UserTypeEnum.TEACHER,
              ]}
            >
              <KlassProgressView />
            </AuthGuard>
          }
        />
        <Route
          path="/admin/student-history/:studentId"
          element={
            <AuthGuard
              roles={[
                UserTypeEnum.SUPER_ADMIN,
                UserTypeEnum.UNIT_ADMIN,
                UserTypeEnum.TEACHER,
              ]}
            >
              <StudentHistoryPage />
            </AuthGuard>
          }
        />

        <Route
          path="/admin/students/"
          element={
            <AuthGuard
              roles={[
                UserTypeEnum.SUPER_ADMIN,
                UserTypeEnum.UNIT_ADMIN,
                UserTypeEnum.TEACHER,
              ]}
            >
              <ManageStudent />
            </AuthGuard>
          }
        />

        <Route
          path={ROUTES.ADMIN.STUDENTS.ACTION(':actionId')}
          element={
            <AuthGuard
              roles={[
                UserTypeEnum.SUPER_ADMIN,
                UserTypeEnum.UNIT_ADMIN,
                UserTypeEnum.TEACHER,
              ]}
            >
              <ManageStudent />
            </AuthGuard>
          }
        />

        <Route
          path="/admin/teachers/"
          element={
            <AuthGuard
              roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
            >
              <ManageTeacher />
            </AuthGuard>
          }
        />
        <Route
          path={ROUTES.ADMIN.TEACHERS.ACTION(':actionId')}
          element={
            <AuthGuard
              roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
            >
              <ManageTeacher />
            </AuthGuard>
          }
        />

        <Route
          path="/admin/team/t/:username"
          element={
            <AuthGuard
              roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
            >
              <ManageTeam />
            </AuthGuard>
          }
        />
        <Route
          path="/admin/trial-lesson"
          element={
            <AuthGuard
              roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
            >
              <ManageTrialLesson />
            </AuthGuard>
          }
        />
        <Route
          path="/admin/team/"
          element={
            <AuthGuard
              roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
            >
              <ManageTeam />
            </AuthGuard>
          }
        />
        <Route
          path={ROUTES.ADMIN.TEAM.ACTION(':actionId')}
          element={
            <AuthGuard
              roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
            >
              <ManageTeam />
            </AuthGuard>
          }
        />
        <Route
          path="/admin/recesscalendars"
          element={
            <AuthGuard roles={[UserTypeEnum.UNIT_ADMIN]}>
              <ManageRecessCalendars />
            </AuthGuard>
          }
        />
        <Route
          path="/admin/topics"
          element={
            <AuthGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
              <TopicsPage />
            </AuthGuard>
          }
        />
        <Route
          path={ROUTES.NOTIFICATIONS.BASE}
          element={
            <AuthGuard
              roles={[
                UserTypeEnum.SUPER_ADMIN,
                UserTypeEnum.UNIT_ADMIN,
                UserTypeEnum.TEACHER,
              ]}
            >
              <NotificationsPage />
            </AuthGuard>
          }
        />
        <Route
          path="/como-funciona"
          element={
            <AuthGuard roles={[UserTypeEnum.PARENT]}>
              <HowItWorksView />
            </AuthGuard>
          }
        />

        <Route path="/courses">
          <Route index element={<CoursesPage />} />
          <Route
            path="/courses/:slugCourseName"
            element={
              <AuthGuard
                roles={[
                  UserTypeEnum.SUPER_ADMIN,
                  UserTypeEnum.UNIT_ADMIN,
                  UserTypeEnum.TEACHER,
                ]}
              >
                <KlassPageAdmin />
              </AuthGuard>
            }
          />
          <Route
            path="/courses/:slugCourseName/class/:klassId"
            element={
              <AuthGuard
                roles={[
                  UserTypeEnum.SUPER_ADMIN,
                  UserTypeEnum.UNIT_ADMIN,
                  UserTypeEnum.TEACHER,
                ]}
              >
                <KlassPageAdmin />
              </AuthGuard>
            }
          />
        </Route>
        <Route path="/forbidden" element={<ErrorPage statusCode={403} />} />
        <Route path="/login" element={<LoginPage />}>
          <Route index element={<LoginHandler />} />
          <Route
            path="reset-password/:uid/u/:token"
            element={<ResetPassword />}
          />
        </Route>
        <Route path="/profile/:username" element={<ProfilePage />} />
        <Route path="*" element={<ErrorPage statusCode={404} />} />

        <Route
          path="/student-lesson-feedback"
          element={
            <AuthGuard
              roles={[
                UserTypeEnum.TEACHER,
                UserTypeEnum.SUPER_ADMIN,
                UserTypeEnum.UNIT_ADMIN,
              ]}
            >
              <TeacherFeedbackPage />
            </AuthGuard>
          }
        />
        <Route path="/agendamento">
          <Route path=":trialLessonId" element={<ConfirmInfoView />} />
          <Route
            path="confirm/:trialLessonEnrollmentId/:trialLessonId"
            element={<TrialLessonSuccessView />}
          />
        </Route>
        <Route
          path="/admin/calendars"
          element={
            <AuthGuard
              roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
            >
              <ManageRecessCalendars />
            </AuthGuard>
          }
        />
        <Route
          path="/admin/units/:unitId/recess-calendar"
          element={
            <AuthGuard
              roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
            >
              <ManageRecessCalendars />
            </AuthGuard>
          }
        />
        <Route
          path="/admin/courses/"
          element={
            <AuthGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
              <ManageCourse />
            </AuthGuard>
          }
        />
        <Route
          path="/admin/courses/:slugCourseName/lessons/editing"
          element={
            <AuthGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
              <ManageEditingCourse page="edit" />
            </AuthGuard>
          }
        />
        <Route
          path="/admin/courses/:slugCourseName/lessons/versioning"
          element={
            <AuthGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
              <ManageEditingCourse page="version" />
            </AuthGuard>
          }
        />
        <Route
          path="/admin/courses/:slugCourseName/lessons/draft"
          element={
            <AuthGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
              <ManageEditingCourse page="draft" />
            </AuthGuard>
          }
        />
        <Route
          path={
            '/admin/courses/:slugCourseName/lessons/:lessonId/:bookType/:bookId'
          }
          element={
            <AuthGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
              <EditMaterialPage />
            </AuthGuard>
          }
        >
          <Route
            path={
              '/admin/courses/:slugCourseName/lessons/:lessonId/:bookType/:bookId/chapter/:chapterId'
            }
            element={
              <AuthGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
                <EditMaterialPage />
              </AuthGuard>
            }
          />
        </Route>
        <Route
          path="/courses/:slugCourseName/lesson/:lessonId/class/:klassId"
          element={<KlassView />}
        >
          <Route index element={<KlassView />} />
          <Route path="activities" element={<KlassView />}>
            <Route index element={<KlassView />} />
            <Route path=":activityId" element={<KlassView />}>
              <Route index element={<KlassView />} />
              <Route path="view" element={<KlassView />} />
            </Route>
            <Route path="homework" element={<KlassView />} />
          </Route>

          <Route path="help" element={<KlassView />} />
          <Route path="book/chapter/:chapterOrder" element={<KlassView />} />
          <Route path="t/book/chapter/:chapterOrder" element={<KlassView />} />
          <Route path="challenge" element={<KlassView />} />
          <Route path="resources" element={<KlassView />} />
          <Route path="resources/:resourceId" element={<KlassView />} />
          <Route
            path="resources/:resourceId/activities/:activityId"
            element={<KlassView />}
          >
            <Route index element={<KlassView />} />
            <Route path="view" element={<KlassView />} />
          </Route>
        </Route>
        <Route
          path="/courses/:slugCourseName/lesson/:lessonId"
          element={<KlassView />}
        >
          <Route index element={<KlassView />} />
          <Route path="activities" element={<KlassView />}>
            <Route index element={<KlassView />} />
            <Route path=":activityId" element={<KlassView />} />
            <Route path="homework" element={<KlassView />} />
          </Route>

          <Route path="help" element={<KlassView />} />
          <Route path="book/chapter/:chapterOrder" element={<KlassView />} />
          <Route path="t/book/chapter/:chapterOrder" element={<KlassView />} />
          <Route path="challenge" element={<KlassView />} />
          <Route path="resources" element={<KlassView />} />
          <Route path="resources/:resourceId" element={<KlassView />} />
          <Route
            path="resources/:resourceId/activities/:activityId"
            element={<KlassView />}
          />
        </Route>
        <Route
          path="/admin/courses/:slugCourseName/lessons/:lessonId/activities/:activityId"
          element={
            <AuthGuard
              roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
            >
              <ManageActivityEdit />
            </AuthGuard>
          }
        ></Route>
        <Route path="/admin/units">
          <Route
            index
            element={
              <AuthGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
                <ManageUnit />
              </AuthGuard>
            }
          />
          <Route
            path=":isAdd"
            element={
              <AuthGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
                <ManageUnit />
              </AuthGuard>
            }
          />
        </Route>
        <Route
          path="/calendar"
          element={
            <AuthGuard
              roles={[
                UserTypeEnum.SUPER_ADMIN,
                UserTypeEnum.TEACHER,
                UserTypeEnum.UNIT_ADMIN,
                UserTypeEnum.PARENT,
              ]}
            >
              <CalendarPage />
            </AuthGuard>
          }
        />
        <Route path="/account" element={<AccountPage />} />
        <Route path="/sugestoes" element={<SuggestionsPage />} />
        <Route
          path="/lesson-report"
          element={
            <AuthGuard roles={[UserTypeEnum.TEACHER]}>
              <ManageScheduledLessonReport />
            </AuthGuard>
          }
        />
        <Route
          path="/class/:klassId/lesson-report/:scheduledLessonId"
          element={
            <AuthGuard
              roles={[
                UserTypeEnum.TEACHER,
                UserTypeEnum.SUPER_ADMIN,
                UserTypeEnum.UNIT_ADMIN,
              ]}
            >
              <ManageScheduledLessonReport />
            </AuthGuard>
          }
        />
        <Route path="/class/:klassId/lessons">
          <Route
            index
            element={
              <AuthGuard
                roles={[
                  UserTypeEnum.TEACHER,
                  UserTypeEnum.SUPER_ADMIN,
                  UserTypeEnum.UNIT_ADMIN,
                ]}
              >
                <KlassLessons />
              </AuthGuard>
            }
          />
          <Route
            path=":lessonId/activities/:activityId"
            element={
              <AuthGuard
                roles={[
                  UserTypeEnum.SUPER_ADMIN,
                  UserTypeEnum.UNIT_ADMIN,
                  UserTypeEnum.TEACHER,
                ]}
              >
                <ManageExtraLessonsActivity />
              </AuthGuard>
            }
          />
        </Route>
        <Route path="*" element={<ErrorPage statusCode={404} />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
